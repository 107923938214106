import './App.css';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { NavBar, HeroSection, MilestoneSection, About, Gallery } from './components';
import Footer from './components/sections/Footer/Footer';
import Flavors from './components/sections/Flavors/Flavors';
import Products from './components/sections/Products/Products';
import withLoader from './components/WithLoader/WithLoader';
import Reserved from './components/sections/Reserved/Reserved';

const App = () => {
  return (
    <BrowserRouter>
      <NavBar />
      <div id="hero">
        <HeroSection />
      </div>
      <div id="milestones">
        <MilestoneSection />
      </div>
      <div id="about">
        <About />
      </div>
      <div id="flavours">
        <Flavors />
      </div>
      <div id="products">
        <Products />
      </div>
      <div id="gallery">
        <Gallery />
      </div>
      <div id="contact">
        <Footer />
      </div>
      <div>
        <Reserved />
      </div>
    </BrowserRouter>
  );
};

export default withLoader(App);
