import React from 'react';
import { iceBackground } from '../../../assets/images';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import mainTheme from '../../../theme/theme';
import {
  bananaBoat,
  bombai,
  chocSpecial,
  doubleScope,
  fruits,
  goatMilk,
  jelly,
  special,
  superSpecial,
  tower,
  waffleSmall,
  wafflelarge,
} from '../../../assets/products';

export default function Products() {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: false,
  });

  return (
    <div
      className="hero min-h-[120vh] flex items-center justify-center pt-24"
      style={{ backgroundColor: '#E0BAE0', backgroundImage: `url(${iceBackground})` }}
    >
      <div className="hero-content flex-col lg:flex-row flex justify-center items-center">
        <div className="flex flex-col items-center" ref={ref}>
          <motion.h1
            initial={{ x: -120 }}
            animate={{ x: inView ? 0 : -120 }}
            transition={{ duration: 1 }}
            className="text-center text-6xl  aboutus-title-animation"
            style={{
              color: mainTheme.colors.secondary,
              fontFamily: mainTheme.typograpghy.headerFont,
            }}
          >
            OUR <span style={{ color: mainTheme.colors.primary }}>Products</span>
          </motion.h1>
          <div className="flex flex-wrap justify-center py-24">
            <ProductCard src={waffleSmall} alt="" productName="Waffle small" />
            <ProductCard src={wafflelarge} alt="" productName="Waffle large" />
            <ProductCard src={tower} alt="" productName="Tower" />
            <ProductCard src={special} alt="" productName="Special" />
            <ProductCard src={superSpecial} alt="" productName="Super special" />
            <ProductCard src={jelly} alt="" productName="Jelly with ice" />
            <ProductCard src={goatMilk} alt="" productName="Goat milk" />
            <ProductCard src={fruits} alt="" productName="Fruits with ice" />
            <ProductCard src={doubleScope} alt="" productName="Double scope" />
            <ProductCard src={chocSpecial} alt="" productName="Chocolate special" />
            <ProductCard src={bombai} alt="" productName="Bombai" />
            <ProductCard src={bananaBoat} alt="" productName="Banana boat" />
          </div>
        </div>
      </div>
    </div>
  );
}

interface ProductCardProps {
  src: string;
  alt: string;
  productName: string;
}

const ProductCard: React.FC<ProductCardProps> = ({ src, alt, productName }) => {
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.5,
  });

  return (
    <div className="flex flex-col px-5 items-center sm:m-0 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5" ref={ref}>
      <motion.img
        initial={{ opacity: 0.2, scale: 0.5 }}
        animate={{ opacity: inView ? 1 : 0.2, scale: inView ? 1 : 0.5 }}
        whileHover={{ scale: 1.4, transition: { duration: 0.25 } }}
        src={src}
        alt={alt}
        className="lg:h-60 h-50"
      />
      <motion.div
        initial={{ y: -30 }}
        animate={{ y: inView ? 0 : -30, transition: { duration: 0.5 } }}
        className="text-center text-[21px] font-medium"
        style={{
          color: mainTheme.colors.fontSecondary,
          fontFamily: mainTheme.typograpghy.headerFont,
          fontWeight: '400',
        }}
      >
        {productName}
      </motion.div>
    </div>
  );
};
