import React from 'react';
import { iceBackground } from '../../../assets/images';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import mainTheme from '../../../theme/theme';
import { photo1, photo2, photo3, stu1, wimal, stu2, stu3, stu4, stu5 } from '../../../assets/images';
export default function Flavors() {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: false,
  });

  return (
    <div
      className="hero min-h-[120vh] flex items-center justify-center pt-24 bg-white"
      style={{ backgroundColor: '#f3f3f3', backgroundImage: `url(${iceBackground})` }}
    >
      <div className="hero-content flex-col lg:flex-row flex justify-center items-center">
        <div className="flex flex-col" ref={ref}>
          <motion.h1
            initial={{ x: -120 }}
            animate={{ x: inView ? 0 : -120 }}
            transition={{ duration: 1 }}
            className="text-center text-6xl  aboutus-title-animation"
            style={{
              color: mainTheme.colors.secondary,
              fontFamily: mainTheme.typograpghy.headerFont,
            }}
          >
            Gallery
          </motion.h1>
          <div className="text-[24px] text-left mt-10 w-full ">Product launch ceremony - Akinra Ice Cream</div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 py-10">
            <img src={photo1} alt="Photo 1" className="w-full h-full object-cover rounded-lg shadow-lg" />
            <img src={photo2} alt="Photo 2" className="w-full h-full object-cover rounded-lg shadow-lg" />
            <img src={photo3} alt="Photo 3" className="w-full h-full object-cover rounded-lg shadow-lg" />
          </div>
          <div className="text-left  w-full text-[16px] leading-relaxed">
            <p>
              Mile stone for Akinra Pvt Ltd – Product launched of Palmyra, Coconut, Kithul & King Coconut ice cream to
              the market By the Hon Minister Arundika Fernando State Minister of Coconut, Kithul and Palmyrah
              Cultivation Promotion and Related Industrial Product Manufacturing & Export Diversification at the
              Ministry of the Plantation at Battaramull on 2nd March 2021.
            </p>
            <p className="mt-4">
              This great event organized by Mr Chrishantha Pathiraja the Chairman of Palmyrah Development Board and the
              staff with participation of Mr. Ravindra Hewavitharana - Secretary to the Ministry of Plantation, Mr.
              Tissa Hewavitharana Secretary to the State Ministry of Coconut, Fishtail Palm, Palmyra and Rubber product
              Promotion and Allied Industrial Production and Export Diversification, Mr. Chinthaka Priyantha Liyanage
              Private Secretary for Hon Minister Dr Pamesh Pathirana of the ministry of plantation. Chairman of Kithul
              Development Board, Director of Coconut Development board and many more government officers.
            </p>
            <p className="mt-4">Our humble gratitude for all of you to participate in this memorable grand event.</p>
            <hr className="my-10" />

            <div className="text-[24px] text-left  w-full ">It’s a great pleasure to present Akinra Ice Cream to</div>
            <div className="text-[24px] text-left w-full ">
              <span className="text-[28px] font-bold text-secondary"> Hon Minister Wimal Weerawansha</span> - Minister
              of Industries.
            </div>
            <img src={wimal} alt="Photo 1" className="w-[75%] h-full object-cover rounded-lg shadow-lg my-10" />
            <hr className="my-10" />

            <div className="text-[28px] text-left  w-full">Akinra ice cream- CSR project</div>
            <div className="text-[24px] text-left w-full ">
              <span className=" font-bold text-secondary">Students of Maris Stella College</span> - Thimbirigaskatuwa
              enjoined with Akinra ice cream.
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 py-10">
              <img src={stu1} alt="stu 1" className="w-full h-full object-cover rounded-lg shadow-lg" />
              <img src={stu2} alt="stu 2" className="w-full h-full object-cover rounded-lg shadow-lg" />
              <img src={stu3} alt="stu 3" className="w-full h-full object-cover rounded-lg shadow-lg" />

              <img src={stu4} alt="stu 2" className="w-full h-full object-cover rounded-lg shadow-lg" />
              <img src={stu5} alt="stu 3" className="w-full h-full object-cover rounded-lg shadow-lg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
